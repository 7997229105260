<template>
  <div class="topnav">
    <a class="active" href="/">Home</a>
    <a href="/dashboard">Dashboard</a>
    <div class="topnav-right">
      <a v-if="!isAuthenticated" href="/signup">Signup</a>
      <a v-if="!isAuthenticated" href="/login">Login</a>
      <a v-else v-on:click="logout">Logout</a>
    </div>
  </div>
</template>
<script>
import API from "@/js/api";
export default {
  name: "Navbar",
  mixins: [],
  components: {},
  data: function () {
    return {};
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"];
    },
    user() {
      return this.$store.getters["auth/user"];
    },
    userIsLoading() {
      return this.$store.getters["auth/isLoading"];
    },
  },
  methods: {
    async logout() {
      var SID = this.$cookies.get("SID");
      await API.logout({ SID: SID });
      this.$cookies.remove("SID");
      await this.$store.dispatch("auth/setUser", { status: false });
      this.$router.push("/login");
    },
  },
};
</script>
<style>
/* Add a black background color to the top navigation */
.topnav {
  background-color: #333;
  overflow: hidden;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: #ddd;
  color: black;
}

/* Add a color to the active/current link */
.topnav a.active {
  background-color: #04aa6d;
  color: white;
}

/* Right-aligned section inside the top navigation */
.topnav-right {
  float: right;
}
</style>