import { createRouter, createWebHistory } from 'vue-router'
//import API from "@/js/api";
import store from '@/store';
const routes = [{
    path: '/',
    name: 'Home',
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import( /* webpackChunkName: "about" */ '../pages/Home.vue')
},
{
    path: '/signup',
    name: 'Signup',
    component: () =>
        import( /* webpackChunkName: "about" */ '../pages/Signup.vue')
},
{
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import( /* webpackChunkName: "about" */ '../pages/Login.vue')
},
{
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
        requiresAuth: true,
    },
    component: () =>
        import( /* webpackChunkName: "about" */ '../pages/Dashboard.vue')
}
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    //according to doc the same result can be achived by the following for this: to.matched.some(record => record.meta.requiresAuth)
    if (to.meta.requiresAuth) {
        let isLoading = store.getters["auth/isLoading"];
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        //if (!auth.loggedIn()) {
        //Explicitly wait till user information is not available
        if (isLoading) {
            var waitInterval = setInterval(async function () {
                isLoading = store.getters["auth/isLoading"];
                if (!isLoading) {
                    clearInterval(waitInterval);
                    let isAuthenticated = store.getters["auth/isAuthenticated"];
                    if (!isAuthenticated) {
                        next({
                            path: '/login',
                            query: { redirect: to.fullPath }
                        });
                    } else {
                        next();
                    }
                }
            }, 300);
        } else {
            let isAuthenticated = store.getters["auth/isAuthenticated"];
            if (!isAuthenticated) {
                next({
                    path: '/login',
                    query: { redirect: to.fullPath }
                });
            } else {
                next();
            }
        }
    } else {
        next(); // make sure to always call next()!
    }
});
export default router