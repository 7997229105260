import axios from 'axios';
export default {

    async getEnv() {
        var response = await axios.get(`/api/v1/test`);
        return response.data;
    },
    async getUser() {
        var response = await axios.get(`/api/v1/me`);
        return response.data;
    },
    async signup(data) {
        var response = await axios.post(`/api/signup`, data);
        return response.data;
    },
    async login(data) {
        var response = await axios.post(`/api/login`, data);
        return response.data;
    },
    async logout(data) {
        var response = await axios.post(`/api/v1/logout`, data);
        return response.data;
    },
    async refresh() {
        var response = await axios.get(`/api/v1/refresh`);
        return response.data;
    },

    async getExperiments() {
        var response = await axios.get(`/api/v1/experiments`);
        return response.data;
    },

    async addExperiment(body) {
        var response = await axios.post(`/api/v1/experiment`, body);
        return response.data;
    },

}